import { default as aboutr1Rqt5RvQaMeta } from "/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue?macro=true";
import { default as events10UvYmvnpCMeta } from "/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93SL8lZrUgz5Meta } from "/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sample0322nM01puMeta } from "/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45products7c8aGHyRT3Meta } from "/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93eEjgOVtRE1Meta } from "/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewI8a8KveFCBMeta } from "/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indextidh4q0A7fMeta } from "/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93LCiAFd4wK8Meta } from "/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as index7dzbu8GEbPMeta } from "/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93IUqiA4qgN3Meta } from "/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexCT30kteExMMeta } from "/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessions4f8ihMa1GHMeta } from "/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingsN73ocjou75Meta } from "/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsVUCz7MFqV4Meta } from "/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93Tf6iRteI8wMeta } from "/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue?macro=true";
import { default as indexHmmz7jw9bzMeta } from "/tmp/build_5493cd87/pages/[studio_url]/buy/product/[product_id]/index.vue?macro=true";
import { default as confirmUmspxGyy0CMeta } from "/tmp/build_5493cd87/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue?macro=true";
import { default as confirmp47DKBwOJBMeta } from "/tmp/build_5493cd87/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue?macro=true";
import { default as calendarQzB0jc2F05Meta } from "/tmp/build_5493cd87/pages/[studio_url]/calendar.vue?macro=true";
import { default as chatE3N7Oi5aFbMeta } from "/tmp/build_5493cd87/pages/[studio_url]/chat.vue?macro=true";
import { default as aboutsqITzHzTpEMeta } from "/tmp/build_5493cd87/pages/[studio_url]/community-groups/[group_id]/about.vue?macro=true";
import { default as feedPJfmZ64VVcMeta } from "/tmp/build_5493cd87/pages/[studio_url]/community-groups/[group_id]/feed.vue?macro=true";
import { default as membersuWIVs2A2P1Meta } from "/tmp/build_5493cd87/pages/[studio_url]/community-groups/[group_id]/members.vue?macro=true";
import { default as _91group_id_93uYAhTlzNHxMeta } from "/tmp/build_5493cd87/pages/[studio_url]/community-groups/[group_id].vue?macro=true";
import { default as community_45profile_45editAcQ37bfhzkMeta } from "/tmp/build_5493cd87/pages/[studio_url]/community-profile-edit.vue?macro=true";
import { default as _91id_939vcCRFRBI2Meta } from "/tmp/build_5493cd87/pages/[studio_url]/community-profile/[id].vue?macro=true";
import { default as communityzzGVXRrrr8Meta } from "/tmp/build_5493cd87/pages/[studio_url]/community.vue?macro=true";
import { default as _91uuid_93gEbzCVMfz1Meta } from "/tmp/build_5493cd87/pages/[studio_url]/complete-registration/[uuid].vue?macro=true";
import { default as _91unique_id_93pP9YJbO7ilMeta } from "/tmp/build_5493cd87/pages/[studio_url]/contracts/[unique_id].vue?macro=true";
import { default as dashboardV0ahj1v8KuMeta } from "/tmp/build_5493cd87/pages/[studio_url]/dashboard.vue?macro=true";
import { default as indexgyp8FJnSb5Meta } from "/tmp/build_5493cd87/pages/[studio_url]/event/details/[event_id]/index.vue?macro=true";
import { default as checkoutaCFZSL2LfDMeta } from "/tmp/build_5493cd87/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue?macro=true";
import { default as select_45productGnb9xrcLbEMeta } from "/tmp/build_5493cd87/pages/[studio_url]/event/details/[event_id]/select-product.vue?macro=true";
import { default as eventshHjJVUjubMMeta } from "/tmp/build_5493cd87/pages/[studio_url]/events.vue?macro=true";
import { default as _91uuid_93gZwBWWEWtxMeta } from "/tmp/build_5493cd87/pages/[studio_url]/gifts/[uuid].vue?macro=true";
import { default as indexNMYzR9rXAiMeta } from "/tmp/build_5493cd87/pages/[studio_url]/gifts/index.vue?macro=true";
import { default as _91invitation_id_93pA0qg0DjlCMeta } from "/tmp/build_5493cd87/pages/[studio_url]/gifts/invitation/[invitation_id].vue?macro=true";
import { default as _91product_id_93yVmiEclCdxMeta } from "/tmp/build_5493cd87/pages/[studio_url]/gifts/product/[product_id].vue?macro=true";
import { default as indexh7rwWjYWU5Meta } from "/tmp/build_5493cd87/pages/[studio_url]/index.vue?macro=true";
import { default as _91invitation_unique_id_936PqXhasa9cMeta } from "/tmp/build_5493cd87/pages/[studio_url]/invitation/[invitation_unique_id].vue?macro=true";
import { default as joinUz6c4VIdSrMeta } from "/tmp/build_5493cd87/pages/[studio_url]/join.vue?macro=true";
import { default as loginMK1RmOaSpPMeta } from "/tmp/build_5493cd87/pages/[studio_url]/login.vue?macro=true";
import { default as logoutarPvzXDg51Meta } from "/tmp/build_5493cd87/pages/[studio_url]/logout.vue?macro=true";
import { default as _91meeting_type_slug_93cvIt5B8Z2SMeta } from "/tmp/build_5493cd87/pages/[studio_url]/meet/[meeting_type_slug].vue?macro=true";
import { default as _91meeting_unique_id_93r5Poi2QLR4Meta } from "/tmp/build_5493cd87/pages/[studio_url]/meet/[meeting_unique_id].vue?macro=true";
import { default as _91meeting_unique_id_93VqzVK0uI7HMeta } from "/tmp/build_5493cd87/pages/[studio_url]/meeting/[meeting_unique_id].vue?macro=true";
import { default as my_45favorites7iJwepxZkXMeta } from "/tmp/build_5493cd87/pages/[studio_url]/my-favorites.vue?macro=true";
import { default as my_45studiosalRu4atqcxMeta } from "/tmp/build_5493cd87/pages/[studio_url]/my-studios.vue?macro=true";
import { default as optinWeVNwCUHCNMeta } from "/tmp/build_5493cd87/pages/[studio_url]/optin.vue?macro=true";
import { default as _91episode_id_93S6zECI0wdmMeta } from "/tmp/build_5493cd87/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue?macro=true";
import { default as indexOQVcLp5uLwMeta } from "/tmp/build_5493cd87/pages/[studio_url]/podcasts/[podcast_id]/index.vue?macro=true";
import { default as indexULBbrhlMeaMeta } from "/tmp/build_5493cd87/pages/[studio_url]/podcasts/index.vue?macro=true";
import { default as aboutYrpE0NbJHAMeta } from "/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/about.vue?macro=true";
import { default as digital_45download_45contentipJtIyL4YgMeta } from "/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/digital-download-content.vue?macro=true";
import { default as events88ZK7fPUC9Meta } from "/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93CPhSOehSHgMeta } from "/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45samplenuim6p2SnbMeta } from "/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsPbPJyXGDCgMeta } from "/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93gmSUnYBvryMeta } from "/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewfugGXf7k1lMeta } from "/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexrPqfPNziYCMeta } from "/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_9328VnBxM8coMeta } from "/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexLMV6dO06zaMeta } from "/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93mKAL3YtqnQMeta } from "/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexXEOiNZ32zcMeta } from "/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsPWWnq29EsOMeta } from "/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingsUqbon2Bf3IMeta } from "/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsqCr2b515KKMeta } from "/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93MdLBhm6CRyMeta } from "/tmp/build_5493cd87/pages/[studio_url]/product/[product_id].vue?macro=true";
import { default as indexUKZJ8cRs6hMeta } from "/tmp/build_5493cd87/pages/[studio_url]/product/index.vue?macro=true";
import { default as indexcrQYOtZjcsMeta } from "/tmp/build_5493cd87/pages/[studio_url]/profile/index.vue?macro=true";
import { default as purchasesZsVh0HxtjvMeta } from "/tmp/build_5493cd87/pages/[studio_url]/profile/purchases.vue?macro=true";
import { default as redemptionsI016Kn2cFGMeta } from "/tmp/build_5493cd87/pages/[studio_url]/profile/redemptions.vue?macro=true";
import { default as _91quiz_id_93lPshfPhS0PMeta } from "/tmp/build_5493cd87/pages/[studio_url]/quiz/[quiz_id].vue?macro=true";
import { default as _91token_93jAC3KZbn5LMeta } from "/tmp/build_5493cd87/pages/[studio_url]/reset-password/[uid]/[token].vue?macro=true";
import { default as resetluGD2EkSmHMeta } from "/tmp/build_5493cd87/pages/[studio_url]/reset.vue?macro=true";
import { default as shopg4PWQmGQawMeta } from "/tmp/build_5493cd87/pages/[studio_url]/shop.vue?macro=true";
import { default as signupSVSMVRkSndMeta } from "/tmp/build_5493cd87/pages/[studio_url]/signup.vue?macro=true";
import { default as liveEffHPgRyWfMeta } from "/tmp/build_5493cd87/pages/[studio_url]/streaming/[event_id]/live.vue?macro=true";
import { default as tips6tUbL5NgTEMeta } from "/tmp/build_5493cd87/pages/[studio_url]/tips.vue?macro=true";
import { default as _91uuid_93kpIoaoXpg4Meta } from "/tmp/build_5493cd87/pages/[studio_url]/waiver/[uuid].vue?macro=true";
import { default as compactbdQm7CDezCMeta } from "/tmp/build_5493cd87/pages/[studio_url]/widgets/calendars/compact.vue?macro=true";
import { default as fullyKcRQJrqYnMeta } from "/tmp/build_5493cd87/pages/[studio_url]/widgets/calendars/full.vue?macro=true";
export default [
  {
    name: _91product_id_93Tf6iRteI8wMeta?.name ?? "bundle-bundle_id-product-product_id",
    path: _91product_id_93Tf6iRteI8wMeta?.path ?? "/bundle/:bundle_id()/product/:product_id()",
    meta: _91product_id_93Tf6iRteI8wMeta || {},
    alias: _91product_id_93Tf6iRteI8wMeta?.alias || [],
    redirect: _91product_id_93Tf6iRteI8wMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutr1Rqt5RvQaMeta?.name ?? "bundle-bundle_id-product-product_id-about",
    path: aboutr1Rqt5RvQaMeta?.path ?? "about",
    meta: aboutr1Rqt5RvQaMeta || {},
    alias: aboutr1Rqt5RvQaMeta?.alias || [],
    redirect: aboutr1Rqt5RvQaMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: events10UvYmvnpCMeta?.name ?? "bundle-bundle_id-product-product_id-events",
    path: events10UvYmvnpCMeta?.path ?? "events",
    meta: events10UvYmvnpCMeta || {},
    alias: events10UvYmvnpCMeta?.alias || [],
    redirect: events10UvYmvnpCMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93SL8lZrUgz5Meta?.name ?? "bundle-bundle_id-product-product_id-free-media-media_id",
    path: _91media_id_93SL8lZrUgz5Meta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93SL8lZrUgz5Meta || {},
    alias: _91media_id_93SL8lZrUgz5Meta?.alias || [],
    redirect: _91media_id_93SL8lZrUgz5Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sample0322nM01puMeta?.name ?? "bundle-bundle_id-product-product_id-free-sample",
    path: free_45sample0322nM01puMeta?.path ?? "free-sample",
    meta: free_45sample0322nM01puMeta || {},
    alias: free_45sample0322nM01puMeta?.alias || [],
    redirect: free_45sample0322nM01puMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45products7c8aGHyRT3Meta?.name ?? "bundle-bundle_id-product-product_id-included-products",
    path: included_45products7c8aGHyRT3Meta?.path ?? "included-products",
    meta: included_45products7c8aGHyRT3Meta || {},
    alias: included_45products7c8aGHyRT3Meta?.alias || [],
    redirect: included_45products7c8aGHyRT3Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93eEjgOVtRE1Meta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93eEjgOVtRE1Meta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93eEjgOVtRE1Meta || {},
    alias: _91media_id_93eEjgOVtRE1Meta?.alias || [],
    redirect: _91media_id_93eEjgOVtRE1Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewI8a8KveFCBMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-view",
    path: viewI8a8KveFCBMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewI8a8KveFCBMeta || {},
    alias: viewI8a8KveFCBMeta?.alias || [],
    redirect: viewI8a8KveFCBMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indextidh4q0A7fMeta?.name ?? "bundle-bundle_id-product-product_id-lessons",
    path: indextidh4q0A7fMeta?.path ?? "lessons",
    meta: indextidh4q0A7fMeta || {},
    alias: indextidh4q0A7fMeta?.alias || [],
    redirect: indextidh4q0A7fMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93LCiAFd4wK8Meta?.name ?? "bundle-bundle_id-product-product_id-media-media_id",
    path: _91media_id_93LCiAFd4wK8Meta?.path ?? "media/:media_id()",
    meta: _91media_id_93LCiAFd4wK8Meta || {},
    alias: _91media_id_93LCiAFd4wK8Meta?.alias || [],
    redirect: _91media_id_93LCiAFd4wK8Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: index7dzbu8GEbPMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id",
    path: index7dzbu8GEbPMeta?.path ?? "playlists/:playlist_id()",
    meta: index7dzbu8GEbPMeta || {},
    alias: index7dzbu8GEbPMeta?.alias || [],
    redirect: index7dzbu8GEbPMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93IUqiA4qgN3Meta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93IUqiA4qgN3Meta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93IUqiA4qgN3Meta || {},
    alias: _91media_id_93IUqiA4qgN3Meta?.alias || [],
    redirect: _91media_id_93IUqiA4qgN3Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexCT30kteExMMeta?.name ?? "bundle-bundle_id-product-product_id-playlists",
    path: indexCT30kteExMMeta?.path ?? "playlists",
    meta: indexCT30kteExMMeta || {},
    alias: indexCT30kteExMMeta?.alias || [],
    redirect: indexCT30kteExMMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessions4f8ihMa1GHMeta?.name ?? "bundle-bundle_id-product-product_id-private-sessions",
    path: private_45sessions4f8ihMa1GHMeta?.path ?? "private-sessions",
    meta: private_45sessions4f8ihMa1GHMeta || {},
    alias: private_45sessions4f8ihMa1GHMeta?.alias || [],
    redirect: private_45sessions4f8ihMa1GHMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingsN73ocjou75Meta?.name ?? "bundle-bundle_id-product-product_id-recordings",
    path: recordingsN73ocjou75Meta?.path ?? "recordings",
    meta: recordingsN73ocjou75Meta || {},
    alias: recordingsN73ocjou75Meta?.alias || [],
    redirect: recordingsN73ocjou75Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsVUCz7MFqV4Meta?.name ?? "bundle-bundle_id-product-product_id-reviews",
    path: reviewsVUCz7MFqV4Meta?.path ?? "reviews",
    meta: reviewsVUCz7MFqV4Meta || {},
    alias: reviewsVUCz7MFqV4Meta?.alias || [],
    redirect: reviewsVUCz7MFqV4Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexHmmz7jw9bzMeta?.name ?? "buy-product-product_id",
    path: indexHmmz7jw9bzMeta?.path ?? "/buy/product/:product_id()",
    meta: indexHmmz7jw9bzMeta || {},
    alias: indexHmmz7jw9bzMeta?.alias || [],
    redirect: indexHmmz7jw9bzMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/buy/product/[product_id]/index.vue").then(m => m.default || m)
  },
  {
    name: confirmUmspxGyy0CMeta?.name ?? "buy-product-product_id-paypal-confirm",
    path: confirmUmspxGyy0CMeta?.path ?? "/buy/product/:product_id()/paypal/confirm",
    meta: confirmUmspxGyy0CMeta || {},
    alias: confirmUmspxGyy0CMeta?.alias || [],
    redirect: confirmUmspxGyy0CMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmp47DKBwOJBMeta?.name ?? "buy-product-product_id-stripe-confirm",
    path: confirmp47DKBwOJBMeta?.path ?? "/buy/product/:product_id()/stripe/confirm",
    meta: confirmp47DKBwOJBMeta || {},
    alias: confirmp47DKBwOJBMeta?.alias || [],
    redirect: confirmp47DKBwOJBMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue").then(m => m.default || m)
  },
  {
    name: calendarQzB0jc2F05Meta?.name ?? "calendar",
    path: calendarQzB0jc2F05Meta?.path ?? "/calendar",
    meta: calendarQzB0jc2F05Meta || {},
    alias: calendarQzB0jc2F05Meta?.alias || [],
    redirect: calendarQzB0jc2F05Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/calendar.vue").then(m => m.default || m)
  },
  {
    name: chatE3N7Oi5aFbMeta?.name ?? "chat",
    path: chatE3N7Oi5aFbMeta?.path ?? "/chat",
    meta: chatE3N7Oi5aFbMeta || {},
    alias: chatE3N7Oi5aFbMeta?.alias || [],
    redirect: chatE3N7Oi5aFbMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/chat.vue").then(m => m.default || m)
  },
  {
    name: _91group_id_93uYAhTlzNHxMeta?.name ?? "community-groups-group_id",
    path: _91group_id_93uYAhTlzNHxMeta?.path ?? "/community-groups/:group_id()",
    meta: _91group_id_93uYAhTlzNHxMeta || {},
    alias: _91group_id_93uYAhTlzNHxMeta?.alias || [],
    redirect: _91group_id_93uYAhTlzNHxMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/community-groups/[group_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutsqITzHzTpEMeta?.name ?? "community-groups-group_id-about",
    path: aboutsqITzHzTpEMeta?.path ?? "about",
    meta: aboutsqITzHzTpEMeta || {},
    alias: aboutsqITzHzTpEMeta?.alias || [],
    redirect: aboutsqITzHzTpEMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/community-groups/[group_id]/about.vue").then(m => m.default || m)
  },
  {
    name: feedPJfmZ64VVcMeta?.name ?? "community-groups-group_id-feed",
    path: feedPJfmZ64VVcMeta?.path ?? "feed",
    meta: feedPJfmZ64VVcMeta || {},
    alias: feedPJfmZ64VVcMeta?.alias || [],
    redirect: feedPJfmZ64VVcMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/community-groups/[group_id]/feed.vue").then(m => m.default || m)
  },
  {
    name: membersuWIVs2A2P1Meta?.name ?? "community-groups-group_id-members",
    path: membersuWIVs2A2P1Meta?.path ?? "members",
    meta: membersuWIVs2A2P1Meta || {},
    alias: membersuWIVs2A2P1Meta?.alias || [],
    redirect: membersuWIVs2A2P1Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/community-groups/[group_id]/members.vue").then(m => m.default || m)
  }
]
  },
  {
    name: community_45profile_45editAcQ37bfhzkMeta?.name ?? "community-profile-edit",
    path: community_45profile_45editAcQ37bfhzkMeta?.path ?? "/community-profile-edit",
    meta: community_45profile_45editAcQ37bfhzkMeta || {},
    alias: community_45profile_45editAcQ37bfhzkMeta?.alias || [],
    redirect: community_45profile_45editAcQ37bfhzkMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/community-profile-edit.vue").then(m => m.default || m)
  },
  {
    name: _91id_939vcCRFRBI2Meta?.name ?? "community-profile-id",
    path: _91id_939vcCRFRBI2Meta?.path ?? "/community-profile/:id()",
    meta: _91id_939vcCRFRBI2Meta || {},
    alias: _91id_939vcCRFRBI2Meta?.alias || [],
    redirect: _91id_939vcCRFRBI2Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/community-profile/[id].vue").then(m => m.default || m)
  },
  {
    name: communityzzGVXRrrr8Meta?.name ?? "community",
    path: communityzzGVXRrrr8Meta?.path ?? "/community",
    meta: communityzzGVXRrrr8Meta || {},
    alias: communityzzGVXRrrr8Meta?.alias || [],
    redirect: communityzzGVXRrrr8Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/community.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93gEbzCVMfz1Meta?.name ?? "complete-registration-uuid",
    path: _91uuid_93gEbzCVMfz1Meta?.path ?? "/complete-registration/:uuid()",
    meta: _91uuid_93gEbzCVMfz1Meta || {},
    alias: _91uuid_93gEbzCVMfz1Meta?.alias || [],
    redirect: _91uuid_93gEbzCVMfz1Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/complete-registration/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91unique_id_93pP9YJbO7ilMeta?.name ?? "contracts-unique_id",
    path: _91unique_id_93pP9YJbO7ilMeta?.path ?? "/contracts/:unique_id()",
    meta: _91unique_id_93pP9YJbO7ilMeta || {},
    alias: _91unique_id_93pP9YJbO7ilMeta?.alias || [],
    redirect: _91unique_id_93pP9YJbO7ilMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/contracts/[unique_id].vue").then(m => m.default || m)
  },
  {
    name: dashboardV0ahj1v8KuMeta?.name ?? "dashboard",
    path: dashboardV0ahj1v8KuMeta?.path ?? "/dashboard",
    meta: dashboardV0ahj1v8KuMeta || {},
    alias: dashboardV0ahj1v8KuMeta?.alias || [],
    redirect: dashboardV0ahj1v8KuMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexgyp8FJnSb5Meta?.name ?? "event-details-event_id",
    path: indexgyp8FJnSb5Meta?.path ?? "/event/details/:event_id()",
    meta: indexgyp8FJnSb5Meta || {},
    alias: indexgyp8FJnSb5Meta?.alias || [],
    redirect: indexgyp8FJnSb5Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/event/details/[event_id]/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutaCFZSL2LfDMeta?.name ?? "event-details-event_id-product-product_id-checkout",
    path: checkoutaCFZSL2LfDMeta?.path ?? "/event/details/:event_id()/product/:product_id()/checkout",
    meta: checkoutaCFZSL2LfDMeta || {},
    alias: checkoutaCFZSL2LfDMeta?.alias || [],
    redirect: checkoutaCFZSL2LfDMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: select_45productGnb9xrcLbEMeta?.name ?? "event-details-event_id-select-product",
    path: select_45productGnb9xrcLbEMeta?.path ?? "/event/details/:event_id()/select-product",
    meta: select_45productGnb9xrcLbEMeta || {},
    alias: select_45productGnb9xrcLbEMeta?.alias || [],
    redirect: select_45productGnb9xrcLbEMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/event/details/[event_id]/select-product.vue").then(m => m.default || m)
  },
  {
    name: eventshHjJVUjubMMeta?.name ?? "events",
    path: eventshHjJVUjubMMeta?.path ?? "/events",
    meta: eventshHjJVUjubMMeta || {},
    alias: eventshHjJVUjubMMeta?.alias || [],
    redirect: eventshHjJVUjubMMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/events.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93gZwBWWEWtxMeta?.name ?? "gifts-uuid",
    path: _91uuid_93gZwBWWEWtxMeta?.path ?? "/gifts/:uuid()",
    meta: _91uuid_93gZwBWWEWtxMeta || {},
    alias: _91uuid_93gZwBWWEWtxMeta?.alias || [],
    redirect: _91uuid_93gZwBWWEWtxMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/gifts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexNMYzR9rXAiMeta?.name ?? "gifts",
    path: indexNMYzR9rXAiMeta?.path ?? "/gifts",
    meta: indexNMYzR9rXAiMeta || {},
    alias: indexNMYzR9rXAiMeta?.alias || [],
    redirect: indexNMYzR9rXAiMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/gifts/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_id_93pA0qg0DjlCMeta?.name ?? "gifts-invitation-invitation_id",
    path: _91invitation_id_93pA0qg0DjlCMeta?.path ?? "/gifts/invitation/:invitation_id()",
    meta: _91invitation_id_93pA0qg0DjlCMeta || {},
    alias: _91invitation_id_93pA0qg0DjlCMeta?.alias || [],
    redirect: _91invitation_id_93pA0qg0DjlCMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/gifts/invitation/[invitation_id].vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93yVmiEclCdxMeta?.name ?? "gifts-product-product_id",
    path: _91product_id_93yVmiEclCdxMeta?.path ?? "/gifts/product/:product_id()",
    meta: _91product_id_93yVmiEclCdxMeta || {},
    alias: _91product_id_93yVmiEclCdxMeta?.alias || [],
    redirect: _91product_id_93yVmiEclCdxMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/gifts/product/[product_id].vue").then(m => m.default || m)
  },
  {
    name: indexh7rwWjYWU5Meta?.name ?? "index",
    path: indexh7rwWjYWU5Meta?.path ?? "/",
    meta: indexh7rwWjYWU5Meta || {},
    alias: indexh7rwWjYWU5Meta?.alias || [],
    redirect: indexh7rwWjYWU5Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_unique_id_936PqXhasa9cMeta?.name ?? "invitation-invitation_unique_id",
    path: _91invitation_unique_id_936PqXhasa9cMeta?.path ?? "/invitation/:invitation_unique_id()",
    meta: _91invitation_unique_id_936PqXhasa9cMeta || {},
    alias: _91invitation_unique_id_936PqXhasa9cMeta?.alias || [],
    redirect: _91invitation_unique_id_936PqXhasa9cMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/invitation/[invitation_unique_id].vue").then(m => m.default || m)
  },
  {
    name: joinUz6c4VIdSrMeta?.name ?? "join",
    path: joinUz6c4VIdSrMeta?.path ?? "/join",
    meta: joinUz6c4VIdSrMeta || {},
    alias: joinUz6c4VIdSrMeta?.alias || [],
    redirect: joinUz6c4VIdSrMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/join.vue").then(m => m.default || m)
  },
  {
    name: loginMK1RmOaSpPMeta?.name ?? "login",
    path: loginMK1RmOaSpPMeta?.path ?? "/login",
    meta: loginMK1RmOaSpPMeta || {},
    alias: loginMK1RmOaSpPMeta?.alias || [],
    redirect: loginMK1RmOaSpPMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/login.vue").then(m => m.default || m)
  },
  {
    name: logoutarPvzXDg51Meta?.name ?? "logout",
    path: logoutarPvzXDg51Meta?.path ?? "/logout",
    meta: logoutarPvzXDg51Meta || {},
    alias: logoutarPvzXDg51Meta?.alias || [],
    redirect: logoutarPvzXDg51Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/logout.vue").then(m => m.default || m)
  },
  {
    name: _91meeting_type_slug_93cvIt5B8Z2SMeta?.name ?? "meet-meeting_type_slug",
    path: _91meeting_type_slug_93cvIt5B8Z2SMeta?.path ?? "/meet/:meeting_type_slug()",
    meta: _91meeting_type_slug_93cvIt5B8Z2SMeta || {},
    alias: _91meeting_type_slug_93cvIt5B8Z2SMeta?.alias || [],
    redirect: _91meeting_type_slug_93cvIt5B8Z2SMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/meet/[meeting_type_slug].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93r5Poi2QLR4Meta?.name ?? "meet-meeting_unique_id",
    path: _91meeting_unique_id_93r5Poi2QLR4Meta?.path ?? "/meet/:meeting_unique_id()",
    meta: _91meeting_unique_id_93r5Poi2QLR4Meta || {},
    alias: _91meeting_unique_id_93r5Poi2QLR4Meta?.alias || [],
    redirect: _91meeting_unique_id_93r5Poi2QLR4Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/meet/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93VqzVK0uI7HMeta?.name ?? "meeting-meeting_unique_id",
    path: _91meeting_unique_id_93VqzVK0uI7HMeta?.path ?? "/meeting/:meeting_unique_id()",
    meta: _91meeting_unique_id_93VqzVK0uI7HMeta || {},
    alias: _91meeting_unique_id_93VqzVK0uI7HMeta?.alias || [],
    redirect: _91meeting_unique_id_93VqzVK0uI7HMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/meeting/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: my_45favorites7iJwepxZkXMeta?.name ?? "my-favorites",
    path: my_45favorites7iJwepxZkXMeta?.path ?? "/my-favorites",
    meta: my_45favorites7iJwepxZkXMeta || {},
    alias: my_45favorites7iJwepxZkXMeta?.alias || [],
    redirect: my_45favorites7iJwepxZkXMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: my_45studiosalRu4atqcxMeta?.name ?? "my-studios",
    path: my_45studiosalRu4atqcxMeta?.path ?? "/my-studios",
    meta: my_45studiosalRu4atqcxMeta || {},
    alias: my_45studiosalRu4atqcxMeta?.alias || [],
    redirect: my_45studiosalRu4atqcxMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/my-studios.vue").then(m => m.default || m)
  },
  {
    name: optinWeVNwCUHCNMeta?.name ?? "optin",
    path: optinWeVNwCUHCNMeta?.path ?? "/optin",
    meta: optinWeVNwCUHCNMeta || {},
    alias: optinWeVNwCUHCNMeta?.alias || [],
    redirect: optinWeVNwCUHCNMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/optin.vue").then(m => m.default || m)
  },
  {
    name: _91episode_id_93S6zECI0wdmMeta?.name ?? "podcasts-podcast_id-episodes-episode_id",
    path: _91episode_id_93S6zECI0wdmMeta?.path ?? "/podcasts/:podcast_id()/episodes/:episode_id()",
    meta: _91episode_id_93S6zECI0wdmMeta || {},
    alias: _91episode_id_93S6zECI0wdmMeta?.alias || [],
    redirect: _91episode_id_93S6zECI0wdmMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/podcasts/[podcast_id]/episodes/[episode_id].vue").then(m => m.default || m)
  },
  {
    name: indexOQVcLp5uLwMeta?.name ?? "podcasts-podcast_id",
    path: indexOQVcLp5uLwMeta?.path ?? "/podcasts/:podcast_id()",
    meta: indexOQVcLp5uLwMeta || {},
    alias: indexOQVcLp5uLwMeta?.alias || [],
    redirect: indexOQVcLp5uLwMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/podcasts/[podcast_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexULBbrhlMeaMeta?.name ?? "podcasts",
    path: indexULBbrhlMeaMeta?.path ?? "/podcasts",
    meta: indexULBbrhlMeaMeta || {},
    alias: indexULBbrhlMeaMeta?.alias || [],
    redirect: indexULBbrhlMeaMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/podcasts/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93MdLBhm6CRyMeta?.name ?? "product-product_id",
    path: _91product_id_93MdLBhm6CRyMeta?.path ?? "/product/:product_id()",
    meta: _91product_id_93MdLBhm6CRyMeta || {},
    alias: _91product_id_93MdLBhm6CRyMeta?.alias || [],
    redirect: _91product_id_93MdLBhm6CRyMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutYrpE0NbJHAMeta?.name ?? "product-product_id-about",
    path: aboutYrpE0NbJHAMeta?.path ?? "about",
    meta: aboutYrpE0NbJHAMeta || {},
    alias: aboutYrpE0NbJHAMeta?.alias || [],
    redirect: aboutYrpE0NbJHAMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: digital_45download_45contentipJtIyL4YgMeta?.name ?? "product-product_id-digital-download-content",
    path: digital_45download_45contentipJtIyL4YgMeta?.path ?? "digital-download-content",
    meta: digital_45download_45contentipJtIyL4YgMeta || {},
    alias: digital_45download_45contentipJtIyL4YgMeta?.alias || [],
    redirect: digital_45download_45contentipJtIyL4YgMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/digital-download-content.vue").then(m => m.default || m)
  },
  {
    name: events88ZK7fPUC9Meta?.name ?? "product-product_id-events",
    path: events88ZK7fPUC9Meta?.path ?? "events",
    meta: events88ZK7fPUC9Meta || {},
    alias: events88ZK7fPUC9Meta?.alias || [],
    redirect: events88ZK7fPUC9Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93CPhSOehSHgMeta?.name ?? "product-product_id-free-media-media_id",
    path: _91media_id_93CPhSOehSHgMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93CPhSOehSHgMeta || {},
    alias: _91media_id_93CPhSOehSHgMeta?.alias || [],
    redirect: _91media_id_93CPhSOehSHgMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45samplenuim6p2SnbMeta?.name ?? "product-product_id-free-sample",
    path: free_45samplenuim6p2SnbMeta?.path ?? "free-sample",
    meta: free_45samplenuim6p2SnbMeta || {},
    alias: free_45samplenuim6p2SnbMeta?.alias || [],
    redirect: free_45samplenuim6p2SnbMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsPbPJyXGDCgMeta?.name ?? "product-product_id-included-products",
    path: included_45productsPbPJyXGDCgMeta?.path ?? "included-products",
    meta: included_45productsPbPJyXGDCgMeta || {},
    alias: included_45productsPbPJyXGDCgMeta?.alias || [],
    redirect: included_45productsPbPJyXGDCgMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93gmSUnYBvryMeta?.name ?? "product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93gmSUnYBvryMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93gmSUnYBvryMeta || {},
    alias: _91media_id_93gmSUnYBvryMeta?.alias || [],
    redirect: _91media_id_93gmSUnYBvryMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewfugGXf7k1lMeta?.name ?? "product-product_id-lessons-lesson_id-view",
    path: viewfugGXf7k1lMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewfugGXf7k1lMeta || {},
    alias: viewfugGXf7k1lMeta?.alias || [],
    redirect: viewfugGXf7k1lMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexrPqfPNziYCMeta?.name ?? "product-product_id-lessons",
    path: indexrPqfPNziYCMeta?.path ?? "lessons",
    meta: indexrPqfPNziYCMeta || {},
    alias: indexrPqfPNziYCMeta?.alias || [],
    redirect: indexrPqfPNziYCMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_9328VnBxM8coMeta?.name ?? "product-product_id-media-media_id",
    path: _91media_id_9328VnBxM8coMeta?.path ?? "media/:media_id()",
    meta: _91media_id_9328VnBxM8coMeta || {},
    alias: _91media_id_9328VnBxM8coMeta?.alias || [],
    redirect: _91media_id_9328VnBxM8coMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexLMV6dO06zaMeta?.name ?? "product-product_id-playlists-playlist_id",
    path: indexLMV6dO06zaMeta?.path ?? "playlists/:playlist_id()",
    meta: indexLMV6dO06zaMeta || {},
    alias: indexLMV6dO06zaMeta?.alias || [],
    redirect: indexLMV6dO06zaMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93mKAL3YtqnQMeta?.name ?? "product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93mKAL3YtqnQMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93mKAL3YtqnQMeta || {},
    alias: _91media_id_93mKAL3YtqnQMeta?.alias || [],
    redirect: _91media_id_93mKAL3YtqnQMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexXEOiNZ32zcMeta?.name ?? "product-product_id-playlists",
    path: indexXEOiNZ32zcMeta?.path ?? "playlists",
    meta: indexXEOiNZ32zcMeta || {},
    alias: indexXEOiNZ32zcMeta?.alias || [],
    redirect: indexXEOiNZ32zcMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsPWWnq29EsOMeta?.name ?? "product-product_id-private-sessions",
    path: private_45sessionsPWWnq29EsOMeta?.path ?? "private-sessions",
    meta: private_45sessionsPWWnq29EsOMeta || {},
    alias: private_45sessionsPWWnq29EsOMeta?.alias || [],
    redirect: private_45sessionsPWWnq29EsOMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingsUqbon2Bf3IMeta?.name ?? "product-product_id-recordings",
    path: recordingsUqbon2Bf3IMeta?.path ?? "recordings",
    meta: recordingsUqbon2Bf3IMeta || {},
    alias: recordingsUqbon2Bf3IMeta?.alias || [],
    redirect: recordingsUqbon2Bf3IMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsqCr2b515KKMeta?.name ?? "product-product_id-reviews",
    path: reviewsqCr2b515KKMeta?.path ?? "reviews",
    meta: reviewsqCr2b515KKMeta || {},
    alias: reviewsqCr2b515KKMeta?.alias || [],
    redirect: reviewsqCr2b515KKMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexUKZJ8cRs6hMeta?.name ?? "product",
    path: indexUKZJ8cRs6hMeta?.path ?? "/product",
    meta: indexUKZJ8cRs6hMeta || {},
    alias: indexUKZJ8cRs6hMeta?.alias || [],
    redirect: indexUKZJ8cRs6hMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/product/index.vue").then(m => m.default || m)
  },
  {
    name: indexcrQYOtZjcsMeta?.name ?? "profile",
    path: indexcrQYOtZjcsMeta?.path ?? "/profile",
    meta: indexcrQYOtZjcsMeta || {},
    alias: indexcrQYOtZjcsMeta?.alias || [],
    redirect: indexcrQYOtZjcsMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: purchasesZsVh0HxtjvMeta?.name ?? "profile-purchases",
    path: purchasesZsVh0HxtjvMeta?.path ?? "/profile/purchases",
    meta: purchasesZsVh0HxtjvMeta || {},
    alias: purchasesZsVh0HxtjvMeta?.alias || [],
    redirect: purchasesZsVh0HxtjvMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/profile/purchases.vue").then(m => m.default || m)
  },
  {
    name: redemptionsI016Kn2cFGMeta?.name ?? "profile-redemptions",
    path: redemptionsI016Kn2cFGMeta?.path ?? "/profile/redemptions",
    meta: redemptionsI016Kn2cFGMeta || {},
    alias: redemptionsI016Kn2cFGMeta?.alias || [],
    redirect: redemptionsI016Kn2cFGMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/profile/redemptions.vue").then(m => m.default || m)
  },
  {
    name: _91quiz_id_93lPshfPhS0PMeta?.name ?? "quiz-quiz_id",
    path: _91quiz_id_93lPshfPhS0PMeta?.path ?? "/quiz/:quiz_id()",
    meta: _91quiz_id_93lPshfPhS0PMeta || {},
    alias: _91quiz_id_93lPshfPhS0PMeta?.alias || [],
    redirect: _91quiz_id_93lPshfPhS0PMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/quiz/[quiz_id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93jAC3KZbn5LMeta?.name ?? "reset-password-uid-token",
    path: _91token_93jAC3KZbn5LMeta?.path ?? "/reset-password/:uid()/:token()",
    meta: _91token_93jAC3KZbn5LMeta || {},
    alias: _91token_93jAC3KZbn5LMeta?.alias || [],
    redirect: _91token_93jAC3KZbn5LMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/reset-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: resetluGD2EkSmHMeta?.name ?? "reset",
    path: resetluGD2EkSmHMeta?.path ?? "/reset",
    meta: resetluGD2EkSmHMeta || {},
    alias: resetluGD2EkSmHMeta?.alias || [],
    redirect: resetluGD2EkSmHMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/reset.vue").then(m => m.default || m)
  },
  {
    name: shopg4PWQmGQawMeta?.name ?? "shop",
    path: shopg4PWQmGQawMeta?.path ?? "/shop",
    meta: shopg4PWQmGQawMeta || {},
    alias: shopg4PWQmGQawMeta?.alias || [],
    redirect: shopg4PWQmGQawMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/shop.vue").then(m => m.default || m)
  },
  {
    name: signupSVSMVRkSndMeta?.name ?? "signup",
    path: signupSVSMVRkSndMeta?.path ?? "/signup",
    meta: signupSVSMVRkSndMeta || {},
    alias: signupSVSMVRkSndMeta?.alias || [],
    redirect: signupSVSMVRkSndMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/signup.vue").then(m => m.default || m)
  },
  {
    name: liveEffHPgRyWfMeta?.name ?? "streaming-event_id-live",
    path: liveEffHPgRyWfMeta?.path ?? "/streaming/:event_id()/live",
    meta: liveEffHPgRyWfMeta || {},
    alias: liveEffHPgRyWfMeta?.alias || [],
    redirect: liveEffHPgRyWfMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/streaming/[event_id]/live.vue").then(m => m.default || m)
  },
  {
    name: tips6tUbL5NgTEMeta?.name ?? "tips",
    path: tips6tUbL5NgTEMeta?.path ?? "/tips",
    meta: tips6tUbL5NgTEMeta || {},
    alias: tips6tUbL5NgTEMeta?.alias || [],
    redirect: tips6tUbL5NgTEMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/tips.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93kpIoaoXpg4Meta?.name ?? "waiver-uuid",
    path: _91uuid_93kpIoaoXpg4Meta?.path ?? "/waiver/:uuid()",
    meta: _91uuid_93kpIoaoXpg4Meta || {},
    alias: _91uuid_93kpIoaoXpg4Meta?.alias || [],
    redirect: _91uuid_93kpIoaoXpg4Meta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/waiver/[uuid].vue").then(m => m.default || m)
  },
  {
    name: compactbdQm7CDezCMeta?.name ?? "widgets-calendars-compact",
    path: compactbdQm7CDezCMeta?.path ?? "/widgets/calendars/compact",
    meta: compactbdQm7CDezCMeta || {},
    alias: compactbdQm7CDezCMeta?.alias || [],
    redirect: compactbdQm7CDezCMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/widgets/calendars/compact.vue").then(m => m.default || m)
  },
  {
    name: fullyKcRQJrqYnMeta?.name ?? "widgets-calendars-full",
    path: fullyKcRQJrqYnMeta?.path ?? "/widgets/calendars/full",
    meta: fullyKcRQJrqYnMeta || {},
    alias: fullyKcRQJrqYnMeta?.alias || [],
    redirect: fullyKcRQJrqYnMeta?.redirect,
    component: () => import("/tmp/build_5493cd87/pages/[studio_url]/widgets/calendars/full.vue").then(m => m.default || m)
  }
]